<template>
	<div v-if="$amp" class="tooltip over">
		<div v-if="!disabled" hidden :data-amp-bind-hidden="`!tooltip${id}`" class="tooltip__content absolute bg-mapplethorpe pin-right pin-top p">
			<slot />
		</div>
		<button :on="`tap:AMP.setState({tooltip${id}: !tooltip${id}})`" class="tooltip__button button--no-style" :class="{'absolute': buttonAbsolute}" c>
			<slot name="button" />
		</button>
	</div>
	<div
		v-else-if="isBasic"
		class="inline-block relative tooltip tooltip--basic"
		@mouseover="!showOnClick ? showTooltip = true : ''"
		@mouseout="!showOnClick ? showTooltip = false : ''"
	>
		<div v-if="!disabled" class="tooltip__content absolute bg-white top-0 p-3" :class="{'hidden': !showTooltip}">
			<slot />
		</div>
		<button
			v-click-outside="handleClick"
			class="tooltip__button button--no-style relative"
			@click="showOnClick ? showTooltip = !showTooltip : ''"
		>
			<slot name="button" />
		</button>
	</div>
	<el-tooltip
		v-else
		v-click-outside="handleClick"
		:value="showTooltip"
		class="tooltip"
		:placement="placement"
		:manual="showOnClick"
		:popper-class="popperClasses"
		:visible-arrow="visibleArrow"
		transition="fade"
		:open-delay="openDelay"
		:disabled="disabled"
	>
		<template #content>
			<div>
				<slot />
			</div>
		</template>
		<component
			:is="useButton ? 'el-button' : 'div'"
			class="tooltip__button button--no-style"
			:class="{'absolute': buttonAbsolute}"
			@click="showOnClick ? showTooltip = !showTooltip : ''"
		>
			<slot name="button" />
		</component>
	</el-tooltip>
</template>

<script>
	import Vue from 'vue';
	import { Tooltip, Button } from '@cognitoforms/element-ui';
	import clickOutside from '../../utilities/clickOutside';
	import { v4 as uuidv4 } from 'uuid';

	Vue.use(Tooltip);
	Vue.use(Button);
	Vue.directive('click-outside', clickOutside);

	export default {
		name: 'CToolTip',
		props: {
			placement: {
				type: String,
				default: null
			},
			popperClass: {
				type: String,
				default: null
			},
			buttonAbsolute: {
				type: Boolean,
				default: false
			},
			visibleArrow: {
				type: Boolean,
				default: false
			},
			showOnClick: {
				type: Boolean,
				default: false
			},
			isAdmin: {
				type: Boolean,
				default: false
			},
			useButton: {
				type: Boolean,
				default: true
			},
			openDelay: {
				type: Number,
				default: 0
			},
			isBasic: {
				type: Boolean,
				default: false
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				showTooltip: false,
				id: uuidv4().replace(/-/g, '')
			};
		},
		computed: {
			popperClasses() {
				let classString = this.popperClass ? this.popperClass + ' ' : '';
				classString += this.isAdmin ? 'admin ' : '';
				classString += !this.showOnClick ? 'el-tooltip__popper--hover ' : '';
				classString += 'fill-lange';
				return classString;
			}
		},
		mounted() {
			document.addEventListener('keyup', this.handleEscape);
		},
		beforeDestroy() {
			document.removeEventListener('keyup', this.handleEscape);
		},
		methods: {
			handleClick(e) {
				if (this.isBasic && !this.showOnClick)
					return;
				if (e.target?.offsetParent?.role !== 'tooltip' && !e.target?.offsetParent?.classList?.contains('el-tooltip__popper') && (e.target?.classList === null || !e.target?.classList?.contains('el-tooltip__popper') || e === 'iframe') && e.target?.tagName !== 'A')
					this.showTooltip = false;
			},
			handleEscape(e) {
				if (e.key === 'Escape' || e.keyCode === 27) {
					this.showTooltip = false;
				}
			}
		}
	};
</script>

<style lang="scss">
$--tooltip-arrow-size: 8px;
$--tooltip-border-color: $ansel;
$--tooltip-fill: $mapplethorpe;

[⚡] .tooltip__content {
	width: 225px;
}

.tooltip__button {
	color: inherit;
	text-align: inherit;
}

.el-tooltip__popper.admin,
html:not(.website) .el-tooltip__popper {
	@include tooltip;
}

.el-tooltip__popper {
	position: absolute;
	z-index: $z-tooltip;
	min-width: 10px;
	max-width: 370px;
	background-color: $mapplethorpe;
	box-shadow: 0 1px 2px 0 rgba($--tooltip-border-color, .7);
	margin-left: math.div(-$--tooltip-arrow-size, 2) + 3px;
	padding: $gutter;
	font-size: inherit;
	word-wrap: break-word;

	@include b-down($b4) {
		max-width: 315px;
	}

	.el-popper__arrow,
	.el-popper__arrow::after {
		display: block;
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-color: transparent;
	}

	.el-popper__arrow {
		border-width: $--tooltip-arrow-size;
	}

	.el-popper__arrow::after {
		border-width: $--tooltip-arrow-size;
		content: ' ';
	}

	&[x-placement^='top'] {
		margin-bottom: $--tooltip-arrow-size;
	}

	&[x-placement^='top'] .el-popper__arrow {
		bottom: -$--tooltip-arrow-size;
		border-bottom-width: 0;
		border-top-color: rgba($--tooltip-border-color, .4);

		&::after {
			bottom: 2px;
			border-bottom-width: 0;
			border-top-color: $--tooltip-fill;
			margin-left: -$--tooltip-arrow-size;
		}
	}

	&[x-placement^='bottom'] {
		margin-top: #{$--tooltip-arrow-size + 6px};
	}

	&[x-placement^='bottom'] .el-popper__arrow {
		top: -$--tooltip-arrow-size;
		border-top-width: 0;
		border-bottom-color: $--tooltip-border-color;

		&::after {
			top: 2px;
			border-top-width: 0;
			border-bottom-color: $--tooltip-fill;
			margin-left: -$--tooltip-arrow-size;
		}
	}

	&[x-placement*='end'] {
		margin-left: math.div($--tooltip-arrow-size, 2);
	}

	:root &--hover.fade-enter-to {
		transition-delay: $speed__hover-delay;
	}
}

.tooltip--basic {

	.tooltip__content {
		left: $size__icon;
		width: max-content;
		border: 1px solid $casablanca;
		box-shadow: 0 1px 2px 0 rgba($--tooltip-border-color, .7);
	}
}

</style>